import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription, of, from } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
    selector: 'sst-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: false
})
export class FileUploadComponent implements OnInit {
  @Output() fileSelectedEvent = new EventEmitter<File>();
  @Input()
  requiredFileType: string;

  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(private http: HttpClient) { }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      //const formData = new FormData();
      //formData.append("thumbnail", file);
      this.fileSelectedEvent.emit(file);
      //this.fileData.append("file", file);
      //const upload$ = this.http.post("/api/thumbnail-upload", formData, {
      //  reportProgress: true,
      //  observe: 'events'
      //})
      //  .pipe(
      //    finalize(() => this.reset())
      //  );

      //this.uploadSub = upload$.subscribe(event => {
      //  if (event.type == HttpEventType.UploadProgress) {
      //    this.uploadProgress = Math.round(100 * (event.loaded / event.total));
      //  }
      //})
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  ngOnInit(): void {
  }

}
