import { EventEmitter, OnInit, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'sst-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    standalone: false
})
export class DatepickerComponent implements OnInit {
  errorClass: string = '';
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() control: UntypedFormGroup;
  @Output() dateChanged: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.control.invalid) {
      this.errorClass = 'mat-form-field-invalid';
    }
  }

  onChange(event: any) {
    this.dateChanged.emit(event);
  }
}
