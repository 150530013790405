import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { IStudentAggregate } from '@userModels/student';
import { Subscription } from 'rxjs';

export interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
    selector: 'sst-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() student: IStudentAggregate;
  public breadcrumbs: IBreadCrumb[]
  subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);

    //this.subscription.add(
    //  this.router.events.pipe(
    //    filter((event: RouterEvent) => event instanceof NavigationEnd),
    //    distinctUntilChanged())
    //    .subscribe(() => {
    //      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    //    }));
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let displayBreadcrumb = route.routeConfig && route.routeConfig.data
      ? route.routeConfig.data.displayBreadcrumb === false ? false : true
      : true;

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    // console.log(path, label)
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];

      // Todo: Make this more dynamic and easily modifiable. Same with the @Input student
      if (lastRoutePart === ':id') {
        path = path.replace(lastRoutePart, `${route.snapshot.params[paramName]}/profile`);
        label = `${this.student.info.firstName} ${this.student.info.lastName}`;
      } else if (lastRoutePart === ':formId') {
        path = path.replace(lastRoutePart, `${route.snapshot.params[paramName]}`);
        label = label;
      } else {
        path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
        label = route.snapshot.params[paramName];
      }
    }

    // In the routeConfig the complete path is not available, so we rebuild it each time
    let nextUrl = path ? `${url}/${path}` : url;

    // Remove /profile from the URL if we're on a URL after it
    // this is only there for the actual profile page, all breadcrumbs after this
    // need to remove /profile from the URL being built up.
    if (nextUrl.includes('/profile') && label != `${this.student.info.firstName} ${this.student.info.lastName}`) {
      nextUrl = nextUrl.replace('/profile', '');
    }

    const breadcrumb: IBreadCrumb = {
        label: label,
        url: nextUrl,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label && displayBreadcrumb ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


