import { Component, Input, OnChanges, OnDestroy, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import _ from 'lodash';

@Component({
    selector: 'sst-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: false
})
export class SelectComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() label: string;
  @Input() valueKey: string;
  @Input() options: any[] = [];
  @Input() required: boolean;
  @Input() control: UntypedFormControl;
  @Input() optionKey: string;
  @Input() multiple: boolean;
  @Input() hint: string;
  @Input() search: boolean = true;
  @Input() showSelectedOptions: boolean = true;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  selectedOptions: any[] = [];
  hideSelectAll: boolean = false;
  selectAllControl: UntypedFormControl = new UntypedFormControl(false);
  filterControl: UntypedFormControl = new UntypedFormControl();
  subscription: Subscription;

  errorClass: string = '';

  ngAfterViewInit() {
    this.subscription = this.control.valueChanges
      .pipe(filter(() => this.multiple))
      .subscribe(() => this.checkValues());

    if (!this.multiple) {
      var option: any;
      option = {};
      option[this.optionKey] = '';
      option[this.valueKey] = null;

      // Check if this.options is not null and is an array before using 'some'
      var blankFound = this.options && Array.isArray(this.options) && this.options.some(x => x[this.optionKey] == '');
      if (!blankFound) {
        this.options?.unshift(option);
      }
    }

    if (this.control.invalid) {
      this.errorClass = 'mat-form-field-invalid';
    }
  }


  ngOnChanges() {
    if (this.multiple) this.checkValues();
  }

  onSelectionChange(obj) {
    this.selectionChanged.emit(obj);
  }

  public filterOptions(value: string = ''): void {
    this.hideSelectAll = value?.length > 0;

    this.options.map(option => {
      const optionValue = !this.optionKey ? option : option[this.optionKey];
      const meetsFilter = optionValue
        .toLocaleLowerCase()
        .includes(value.toLowerCase());

      option.isHidden = !meetsFilter;
    });
  }

  public toggleSelectAll(): void {
    if (this.selectAllControl.value === true) {
      const allOptionValues = this.options.map(option => !this.valueKey ? option : option[this.valueKey]);
      this.control.patchValue([...allOptionValues]);
    } else {
      this.control.patchValue([]);
      this.selectAllControl.setValue(false);
    }

    this.refreshSelectedOptions();
  }

  public onPanelClose(): void {
    this.filterControl.setValue('');
    this.hideSelectAll = false;
    this.filterOptions();
  }

  // Selects or deselects "Select All" option depending on selected option values.
  public checkValues() {
    this.refreshSelectedOptions();

    if (!this.options) {
      return;
    }

    if (this.selectedOptions.length === this.options.length) this.selectAllControl.setValue(true);
    else this.selectAllControl?.setValue(false);
  }


  public disabledSelectedOption(event: MatCheckboxChange, option: any) {
    if (event.checked === false) {
      const optionValue = !this.valueKey ? option : option[this.valueKey];
      const values = this.control.value.filter(value => value !== optionValue);

      this.control.patchValue([...values]);
      this.control.markAsDirty();

      this.checkValues();
    }
  }

  private refreshSelectedOptions() {
    if (!this.options) {
      return;
    }
    this.selectedOptions = this.options.filter(option => {
      const optionValue = !this.valueKey ? option : option[this.valueKey];
      return this.control.value.find(value => optionValue === value) !== undefined;
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
