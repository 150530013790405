import { Component, Input } from '@angular/core';

@Component({
    selector: 'sst-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
    standalone: false
})
export class MessageBoxComponent {
  @Input() color: 'primary' | 'error';
}
