import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'sst-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: false
})
export class InputComponent implements OnInit {
  
  visible: boolean = true;

  errorClass: string = '';

  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() type: string;
  @Input() required: boolean;
  @Input() control: UntypedFormGroup;
  @Input() iconPrefix: string;
  @Input() iconSuffix: string;
  @Input() readonly: boolean = false;
  @Output() suffixClick: EventEmitter<string> = new EventEmitter();
  @Output() prefixClick: EventEmitter<string> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();

  @Input() maxLength: number = 5000;
  focused: boolean;
  ngOnInit(): void {
    if (this.control.invalid) {
      this.errorClass = 'mat-form-field-invalid';
    }
  }
  clickSuffix() {
    this.suffixClick.emit(this.iconSuffix);
  }

  clickPrefix() {
    this.prefixClick.emit(this.iconPrefix);
  }

  togglePasswordVisilibility() {
    this.visible = !this.visible;
  }

  onBlur() {
    this.focused = false;
    this.blur.emit();
  }

  onFocus() {
    this.focused = true;
  }
}
