import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sst-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    standalone: false
})
export class LabelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
